@import '../../../../styles/gradient';
@import '../../../../styles/media';
@import '../../../../styles/responsiveText';
@import '../../../../styles/limitMaxWidth.module';

.splashPageCTAContainer {
    margin: 0 1rem;

    .splashPageCTA {
        @include limit-max-width;
        display: flex;
        justify-content: center;
        column-gap: 8rem;
        width: 100%;
        margin: 5rem auto;

        padding: 2rem;
        background: var(--splash-page-background-gradient);
        border-radius: 12px;
        color: var(--text-color);

        @include respond-to(md) {
            flex-direction: column;
        }

        .header {
            @include responsive-text-medium;
            font-weight: 700;
            text-align: center;
        }

        .ctaButton {
            display: flex;
            justify-content: center;
            @include responsive-text-medium;

            @include respond-to(md) {
                margin: 1.5rem auto 0;
            }
        }
    }
}
