@import '../../../../../styles/limitMaxWidth.module';

.splashPagePersonDescription {
    @include limit-max-width;

    display: flex;
    flex-direction: column;
    color: var(--text-color);

    .splashPagePersonDescriptionHeader {
        text-align: center;
        padding-bottom: 2rem;
        font-size: 56px;

        .splashPagePersonDescriptionHeaderFirstName {
            font-weight: $smaller-bold-weight;
            padding-bottom: 0.5rem;
            margin: 0 auto;
        }

        .splashPagePersonDescriptionHeaderJepJobTitle {
            font-size: 50%;
        }

        .splashPagePersonDescriptionHeaderCurrentEmployer {
            font-size: 50%;
        }
    }

    .splashPagePersonDescriptionBody {
    }

    padding: 0 6rem;

    @media (max-width: $small-screen-max-width) {
        padding: 0 3rem;
    }
}
