@import '../../../../../styles/responsiveText';
@import '../../../../../styles/variables';
@import '../../../../../styles/delayed';

.splashPageContainer {
    z-index: 2;

    background-color: var(--splash-page-top-container);
    color: var(--gray);
    height: fit-content;
    width: min-content;
    border-radius: 12px;
    margin: 0 auto;
    padding: 1.5rem;

    .splashPageContainerTitle {
        @include responsive-text-medium-small;
        font-weight: $default-bold-weight;
        white-space: nowrap;
    }

    .splashPageContainerSubTitle {
        @include responsive-text-extra-small;
        padding-top: 1rem;
    }

    .splashPageContainerButtonContainer {
        padding: 1rem 0;
        display: flex;
        justify-content: space-around;
        column-gap: 2rem;

        .splashPageContainerButton {
            @include responsive-text-small;
            padding-bottom: 0.625rem;

            color: var(--text-color);
            cursor: pointer;
            border-bottom: 2px solid transparent;
            @include delayed;

            &.isActive {
                border-bottom: solid 2px var(--color-four);
                color: var(--color-four);
                font-weight: $default-bold-weight;
            }

            &:hover {
                border-bottom: solid 2px var(--color-four);
            }
        }
    }

    .splashPageContainerContentContainer {
    }
}

.splashPageSmallTextContainer {
    a {
        &.splashPageSmallText {
            margin: 0.5rem auto 0;
            width: fit-content;
            //display: flex;
            //justify-content: center;
        }
    }
}
