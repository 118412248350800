@import '../../../../../styles/variables';
@import '../../../../../styles/delayed';

$width-to-height-ratio: 3/4;
$large-to-small-ratio: 1.75;

$small-image-max-height: 200px;
$small-image-variable-height: 16vw;
$small-image-min-height: 100px;

$large-image-max-height: $small-image-max-height * $large-to-small-ratio;
$large-image-variable-height: $small-image-variable-height *
    $large-to-small-ratio;
$large-image-min-height: $small-image-min-height * $large-to-small-ratio;

$transition-duration: 0.5s;

.splashPagePerson {
    max-height: $large-image-variable-height;
    user-select: none;
    border-radius: 8px;
    margin: 0.5rem;

    @include delayed;
    &.hover {
        box-shadow: var(--color-eleven) 0 0 5px 2px;
    }

    @media (max-width: $small-screen-max-width) {
        max-height: $large-image-min-height;
    }

    @media (min-width: $large-screen-min-width) {
        max-height: $large-image-max-height;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    //object-fit: cover;

    img {
        transition: $transition-duration all;
        border-radius: 8px;

        max-height: $small-image-variable-height;
        width: $small-image-variable-height * $width-to-height-ratio;

        @media (max-width: $small-screen-max-width) {
            max-height: $small-image-min-height;
            width: $small-image-min-height * $width-to-height-ratio;
        }

        @media (min-width: $large-screen-min-width) {
            max-height: $small-image-max-height;
            width: $small-image-max-height * $width-to-height-ratio;
        }
    }
}
