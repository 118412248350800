@import '../../../../styles/delayed';

.linkItem {
    display: block;
    color: var(--color-three);
    @include delayed;
    @include delayedHoverUnderline;

    &.inline {
        display: inline;
    }

    &:hover {
        color: var(--color-three);
    }
}
