@import '../../../../styles/delayed';
@import '../../../../styles/variables';

.splashPageTeam {
    background-color: var(--splash-page-team-container);
    padding: 2rem 0;

    .title {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        font-size: 60px;
        font-weight: 700;
        flex-wrap: wrap;
        color: var(--text-color);

        .emphasis {
            color: var(--color-one);
        }
    }

    .carouselContainer {
        display: flex;
        justify-content: center;
        overflow-x: hidden;

        .arrowContainer {
            padding: 1rem;
            height: fit-content;
        }

        .displayedPeopleContainer {
            width: calc(100vw - 84px - 48px);
            flex: 1 1 auto;
            overflow-x: hidden;
            margin: 2rem 1rem;

            .swipeable-views-slider-container {
                width: fit-content;
            }
        }
    }

    .dotContainer {
        display: flex;
        justify-content: center;
        column-gap: 1rem;
        margin-bottom: 1rem;

        .base {
            rect {
                fill: var(--another-gray); // different
                cursor: pointer;
                @include delayed;

                &:hover {
                    fill: var(--color-five);
                }
            }
        }

        .active {
            rect {
                fill: var(--color-five);
            }
        }
    }

    .subPeople {
        display: flex !important;
        justify-content: center;
        column-gap: 2rem;
    }

    .pictureContainer {
        margin: 3rem;
        display: flex;
        justify-content: center;
    }

    .teamMemberDetails {
    }
}
