.splashPageContainerCTAButtonContainer {
    display: flex;
    justify-content: space-evenly;
    padding-top: 1rem;
    column-gap: 1rem;

    .splashPageContainerBuyButtonContainer {
        flex: 1;
    }

    .splashPageContainerDemoButtonContainer {
        flex: 1;
        display: flex;
        flex-direction: column;

        .splashPageContainerDemoButtonSubtext {
            margin: 0.5rem auto 0;
        }
    }

    // TODO: Not sure if this is necessary anymore, can try deleting
    a {
        button {
            width: 100%;
        }
    }

    button {
        width: 100%;
    }
}
