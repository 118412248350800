@import '../../../../../../styles/responsiveText';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/media';

.baseValueProposition {
    background-color: var(--highlight);
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 12px;
    @include respond-to(sm) {
        margin: 0;
        border-radius: 0;
    }

    .title {
        color: var(--color-five);
        @include responsive-text-medium;
        font-weight: $default-bold-weight;
        margin: 0.5rem;
    }

    .description {
        @include responsive-text-extra-small;
        color: var(--text-color);
        margin: 0.5rem;
        line-height: 1.5715;
    }

    .imageContainer {
        display: flex;
        justify-content: center;

        img {
            margin-top: 1rem;
            width: 20vw;
            height: auto;
            min-width: 275px;
            border-radius: $border-radius;

            @include respond-to(sm) {
                width: 100%;
            }
        }
    }
}
