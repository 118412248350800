.fadeInOut {
    &.visible {
        opacity: 1;
    }

    &.notVisible {
        opacity: 0;
        pointer-events: none;
    }
}
