@import '../../../../../styles/responsiveText';
@import '../../../../../styles/variables';
@import '../../../../../styles/media';

.text {
    color: var(--text-color);
    margin: 2rem;
    @include respond-to(sm) {
        margin-top: 0;
    }

    .titleText {
        @include responsive-text-large;
        width: 646px;
        font-weight: $default-bold-weight;

        .emphasis {
            color: var(--color-five);
        }
    }

    .subtitleText {
        @include responsive-text-small;
        line-height: $line-height;
        margin-top: 2rem;
    }
}
