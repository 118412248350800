@import '../../../../styles/gradient';
@import '../../../../styles/limitMaxWidth.module';
@import '../../../../styles/media';

.splashPageMiddleSection {
    padding: 2rem 0;
    background: var(--splash-page-middle-gradient);
    position: relative;
    z-index: 2;

    .valuePropositionContainer {
        @include limit-max-width;
        margin: 0 auto;
        grid-gap: 1rem;
        grid-template-rows: repeat(4, 1fr); // 4 rows with equal height
        grid-template-columns: repeat(2, 1fr); // 2 columns with equal width

        .bigValueProposition {
            grid-column: span 2;
            max-width: $large-screen-min-width;
        }

        .multipleValuePropositions {
            display: flex;
            max-width: $large-screen-min-width;

            @include respond-to(sm) {
                flex-direction: column;
            }
        }
    }
}
