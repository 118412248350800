@import '../../../../../../styles/responsiveText';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/media';

.passRateContainer {
    display: flex;
    flex: 1;
    flex-direction: column-reverse;

    .passRateNumber {
        margin: 0 auto;
        color: var(--text-color);
        font-size: 72px;
        @include respond-to(sm) {
            font-size: 56px;
        }
    }

    .outer {
        height: 100%;

        .middle {
            display: flex;
            height: 100%;

            .inner {
                position: relative;
                display: flex;
                flex-direction: column-reverse;

                .barChart {
                    transition: height 1s linear;

                    max-width: 10rem;
                    @include respond-to(sm) {
                        max-width: 7rem;
                    }
                    border-radius: 12px;
                    display: flex;
                    flex-direction: column-reverse;

                    .barChartText {
                        width: 100%;
                        margin: 0 auto;
                        text-align: center;
                        @include responsive-text-medium-small;
                        padding-bottom: 1rem;
                        font-weight: $default-bold-weight;

                        &.ours {
                            color: var(--text-color);
                        }

                        &.theirs {
                            color: var(--text-color);
                        }
                    }

                    &.ourPassRate {
                        background-color: var(--color-six);
                    }

                    &.theirPassRate {
                        background-color: var(--color-five);
                    }
                }
            }
        }
    }
}
