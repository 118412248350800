@import '../../../../styles/gradient';
@import '../../../../styles/media';
@import '../../../../styles/limitMaxWidth.module';
@import '../../../../styles/centerChildrenVertically.module';

.splashPageTopSectionContainer {
    width: 100%;
    background: var(--splash-page-background-gradient);

    height: 100%;
    padding: 1rem 0 1.5rem 0;
    @include respond-to(sm) {
        padding-bottom: 2rem;
        padding-top: 2rem;
    }

    .splashPageTopSection {
        position: relative;
        z-index: 1;
        margin-left: 2rem;
        display: flex;
        flex-wrap: wrap;
        row-gap: 2rem;
        opacity: 100%;
        @include limit-max-width;

        .topSectionLeft {
            @include center-children-vertically;
            flex: 1;
            margin-left: 1.5rem;
            @include respond-to(xs) {
                margin-left: 0;
            }

            .splashPageValuePropContainer {
                display: flex;
                column-gap: 3rem;
                padding-top: 2rem;
                margin-left: 2rem;
                margin-right: 1rem;
            }
        }

        .topSectionRight {
            @include center-children-vertically;
            flex: 1;
        }
    }
}

.landingImage {
    max-width: 100vw;
    max-height: 600px;
    position: absolute;
    top: 10rem;
    bottom: 0;
    left: 15%;
    height: 50vh;
    @include respond-to(xl) {
        height: 40vh;
        left: 0;
    }
    overflow: hidden;
    width: auto;
    opacity: 0.15;
    z-index: 5;
}
