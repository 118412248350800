@import '../../../styles/variables';

.loomContainer {
    display: block;
    margin: 3rem auto;
    @media (max-width: $small-screen-max-width) {
        margin: 1rem auto;
    }
    width: 100%;
    height: auto;
    max-width: 800px;

    iframe {
        width: 100%;
        height: 100%;
        border-radius: $border-radius;
    }
}
