@import '../../../../../styles/responsiveText';
@import '../../../../../styles/variables';

.valueProp {
    color: var(--text-color);
    max-width: 15rem;

    .titleAndIcon {
        position: relative;
        width: fit-content;

        .title {
            @include responsive-text-small;
            font-weight: $default-bold-weight;
        }

        .icon {
            background-color: var(--color-six);
            border-radius: 50%;
            position: absolute;

            display: flex;
            flex-direction: column;
            justify-content: center;

            width: 32px;
            height: 32px;
            top: -25px;
            left: -25px;

            svg {
                margin: 0 auto;
            }

            @media (max-width: $mobile-mode-breakpoint-logged-out) {
                width: 24px;
                height: 24px;
                top: -20px;
                left: -20px;

                svg {
                    scale: 75%;
                }
            }

            @media (max-width: $small-screen-max-width) {
                width: 22px;
                height: 22px;
                top: -18px;
                left: -18px;

                svg {
                    scale: 60%;
                }
            }
        }
    }

    .subtitle {
        @include responsive-text-extra-small;
        margin-top: 0.5rem;
    }
}
