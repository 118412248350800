@import '../../../../../../styles/responsiveText';
@import '../../../../../../styles/variables';

.splashPage2ContainerInfoContainer {
    color: white;
    width: fit-content;

    .containerInfoTitleContainer {
        margin-bottom: 0.5rem;

        .containerInfoTitle {
            @include responsive-text-small;
            color: var(--color-five);
            font-weight: $default-bold-weight;
        }

        .containerInfoSubtitle {
            @include responsive-text-extra-small;

            line-height: $line-height;
            color: var(--gray);
        }
    }

    .containerInfoText {
        @include responsive-text-extra-small;
        color: var(--text-color);
        ul {
            padding-inline-start: 2rem;
        }
    }
}
