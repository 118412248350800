@import '../../../../styles/variables';
@import '../../../../styles/media';

$vertical-view-max-width-px: 800px;

.screenshot {
    display: flex;
    justify-content: center;
    min-width: calc($min-width - 6rem);
    @include respond-to(sm) {
        margin: 0;
    }

    img {
        border-radius: 0.5rem;
        overflow: hidden;
        object-fit: cover;
        max-height: 50vh;
        box-shadow:
            inset 0 1px 1px 0 hsl(0deg, 0%, 100%, 10%),
            0 20px 40px -20px rgb(50, 50, 53, 45%),
            0 30px 60px -30px rgb(0, 0, 0, 30%);
    }
}
