@import '../../../../../styles/responsiveText';
@import '../../../../../styles/variables';
@import '../../../../../styles/media';
@import '../../../../../styles/limitMaxWidth.module';

.successRateContainer {
    @include limit-max-width;

    .text {
        text-align: center;
        margin: 0 auto;
        @include responsive-text-large;
        font-weight: $default-bold-weight;
        color: var(--text-color);
        padding-bottom: 3rem;

        .emphasis {
            color: var(--color-five);
        }
    }

    .barChartSection {
        margin: 0 auto;
        width: fit-content;
        display: flex;
        column-gap: 1rem;
    }

    .sourceReference {
        width: fit-content;
        margin: 0 auto;
        padding: 1rem;
    }
}
